import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles/";

const useStyles = makeStyles(theme => ({
    dialogueHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'DBDDE2',
        margin: theme.spacing(1),
    },
    dialogueBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Verdana',
        color: 'black',
        margin: theme.spacing(1),
        backgroundColor: 'DBDDE2',
    },
    dialogueLogo: {
        height: '120px',
        width: '100%',
    },
    dialogue: {
    },
}));


const FeedbackDialog = (props) => {
    const {fullScreen, open, handleClose, sendFeedback} = props;
    const classes = useStyles();

    const [feedbackText, setFeedbackText] = useState('');

    const updateFeedbackText = (event) => {
        setFeedbackText(event.target.value);
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.dialogue}
            PaperProps={{
                style: {
                  backgroundColor: '#DBDDE2',
                },
              }}
        >
            <DialogTitle id="responsive-dialog-title">
                Welcome to the Pubmash Beta!
            </DialogTitle>
            <DialogContent>
                <React.Fragment>
                    <Typography variant={"body2"} className={classes.dialogueBody}>
                        As this is the beta, this means that we are still trying to figure out what makes Pubmash as awesome to use as possible.
                    </Typography>
                    <Typography variant={"body2"} className={classes.dialogueBody}>
                        That's why we want to here from you! Please tell us what features you would love to see in Pubmash, and we will see if we can make them for you. Cheers!
                    </Typography>
                    <Typography variant={"body2"} className={classes.dialogueBody}>
                        We also have a public Trello board, if you are curious about stuff we are already working and released features. You can also create a Trello user and vote on features!
                    </Typography>
                    <Typography variant={"body2"} className={classes.dialogueBody}>
                        <a href={"https://trello.com/b/a5XUl3Cr/pubmash-public"} target={"_blank"} rel="noopener noreferrer" >Link to trello board</a>
                    </Typography>
                    <TextField
                        label="Feedback"
                        style={{ margin: 8 }}
                        placeholder="Tell us what you would like in Pubmash"
                        fullWidth
                        multiline
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => updateFeedbackText(event)}
                    />
                </React.Fragment>
            </DialogContent>
            <DialogActions className={classes.dialogueHeader}>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button onClick={() => sendFeedback(feedbackText)}
                        variant={"contained"}
                        color="primary">
                    Send suggestion
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FeedbackDialog;