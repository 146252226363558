import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import {StyledBadge} from "./StyledBadge";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    
}));

const FriendCard = (props) => {
    const {user, joinFriendsPubClicked} = props;
    const classes = useStyles();

    const joinFriendsPubClickedHandler = (event) => {
        if(joinFriendsPubClicked)
            joinFriendsPubClicked(user.pubId);
    }

    let friendStatus = "Not logged in";

    if (user.online) {
        friendStatus = user.pubName
            ? `Last seen in ${user.pubName.substring(0, 3) == "The" ? "" : "The"} ${user.pubName}`
            : `Not in a pub`;
    }
    else if(user.lastActive) {
        friendStatus = `Last active ${moment(user.lastActive).fromNow()}`;
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', margin: '25px'}}>
            {
                user.online
                    ?
                    <StyledBadge
                        style={{marginRight: '25px', height: '50px', width: '50px'}}
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        variant="dot"
                    >
                        <Avatar
                            style={{marginRight: '25px', height: '50px', width: '50px'}}
                            src={user.photoURL}
                        />
                    </StyledBadge>
                    :
                    <Avatar
                        style={{marginRight: '25px', height: '50px', width: '50px'}}
                        src={user.photoURL}
                    />
            }
            <div style={{flex: 'auto'}}>
                <Typography
                    component="span"
                    variant="body2"
                    style={{display: 'block', fontSize: '1.5em', color: '#333'}}
                    color="textPrimary"
                >
                    {user.name}
                </Typography>
                <Typography
                    component="span"
                    variant="body2"
                    style={{display: 'block', fontSize: '1.0em', color: user.online ? '#D3C488' : '#C2C2C2'}}
                    color="textPrimary"
                >
                    {friendStatus}
                </Typography>
            </div>
            {
                user.pubId &&
                <Button onClick={joinFriendsPubClickedHandler} variant="contained" color="primary">
                    Go to the pub
                </Button>
            }
        </div>
    );
}

export default FriendCard;