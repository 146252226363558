import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import React, {useState} from "react";
import {ReactComponent as Logo} from "../assets/Logo.svg";
import DialogActions from "@material-ui/core/DialogActions";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles/";

const useStyles = makeStyles(theme => ({
    dialogueHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'DBDDE2',
        margin: theme.spacing(1),
        
    },
    dialogueBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Verdana',
        color: 'black',
        margin: theme.spacing(1),
        backgroundColor: 'DBDDE2',
    },
    dialogueLogo: {
        height: '120px',
        width: '100%',
    },
    dialogue: {
    },
}));


const WhatIsPubMashDialog = (props) => {
    const {fullScreen, open, handleClose} = props;
    const classes = useStyles();
    
    const [activeStep, setActiveStep] = useState(0);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.dialogue}
            PaperProps={{
                style: {
                  backgroundColor: '#DBDDE2',
                },
              }}
        >
            <DialogTitle id="responsive-dialog-title">
                Welcome to Pubmash!
            </DialogTitle>
            <DialogContent>
                {
                    activeStep === 0 &&
                    <React.Fragment>
                        <Logo className={classes.dialogueLogo}/>
                        <Typography variant={"h6"} className={classes.dialogueHeader}>
                            What is Pubmash?
                        </Typography>

                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            It’s the pub! It is a place to meet your friends like you always have done.
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            It’s somewhere to sit around a table with your friends and chat or watch videos together.
                            And if you wish, it’s a place to meet others.
                        </Typography>
                        <Typography variant={"h6"} className={classes.dialogueHeader}>
                            So I actually sit at a table, with my friends?
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            Yep. And as you move your head around, so does your virtual self in the pub.
                        </Typography>
                    </React.Fragment>
                }
                {
                    activeStep === 1 &&
                    <React.Fragment>

                        <Typography variant={"h6"} className={classes.dialogueHeader}>
                            A Pub is a social place buzzing full of people!
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            If there’s other people in your pub you may see their profile picture sitting at a nearby
                            table.
                        </Typography>
                        <Typography variant={"h6"} className={classes.dialogueHeader}>
                            Video heads only work on the table you are at
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            Each person can see only see the video head of others sitting at their table - you cannot
                            see video of other tables.
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            Instead you can only see the profile picture of people on nearby tables.
                        </Typography>
                        <Typography variant={"h6"} className={classes.dialogueHeader}>
                            So who is that at the table next to us?
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            Curious as to who is at the nearby table? If you want to chat or make friends to people at
                            another table, you can ask to join them or invite them to your table!
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            If everyone agrees, then you can chat and make friends with your fellow pub goers.
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            If you just want to chat privately with your friends, then just stay at your table and chat
                            - just like in a real pub.
                        </Typography>
                    </React.Fragment>
                }
                {
                    activeStep === 2 &&
                    <React.Fragment>
                        <Typography variant={"h6"} className={classes.dialogueHeader}>
                            Watch videos together
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            You and your friends can watch videos together in the pub. Many tables have LCD screens on
                            the wall.
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            If you like, choose a Youtube video to watch, and then watch it together.
                        </Typography>

                        <Typography variant={"h6"} className={classes.dialogueHeader}>
                            Clap, say cheers and be merry!
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            You can show your appreciation by clapping in Pubmash. You can also show your fantastic
                            fashion sense and wear different hats.
                        </Typography>

                        <Typography variant={"h6"} className={classes.dialogueHeader}>
                            More on the way...
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            There are many new features on the way in the pubs.
                        </Typography>
                        <Typography variant={"body2"} className={classes.dialogueBody}>
                            Rumour has it there is even a new room that is under construction… Maybe you can see it by
                            swapping to top-down view by pressing K in the pub.
                        </Typography>
                    </React.Fragment>
                }
            </DialogContent>
            <DialogActions className={classes.dialogueHeader}>
                <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={activeStep}
                    className={classes.dialogueHeader}
                    nextButton={
                        <Button size="small" onClick={() => setActiveStep(activeStep + 1)} disabled={activeStep === 2}>
                            Next
                            {<KeyboardArrowRight/>}
                        </Button>}
                    backButton={
                        <Button size="small" onClick={() => setActiveStep(activeStep - 1)} disabled={activeStep === 0}>
                            {<KeyboardArrowLeft/>}
                            Back
                        </Button>}
                />
            </DialogActions>
            <Divider />
            <Typography variant={"caption"} align={"center"} style={{margin: '10px auto 0px auto', fontFamily: "Roboto"}}>
                A production by Ole Andreas Haley & Matthew Smalley
            </Typography>
            <Typography variant={"caption"} align={"center"} style={{margin: '0px auto 15px auto', fontFamily: "Roboto"}}>
                If you need to reach us, send us an email at <a href={"mailto:cheers@pubmash.com"} target={"_blank"} rel="noopener noreferrer" >cheers@pubmash.com</a>
            </Typography>
        </Dialog>
    );
}

export default WhatIsPubMashDialog;