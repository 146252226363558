import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import React, {useState, useEffect, useRef} from "react";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles/";

const useStyles = makeStyles(theme => ({
    dialogueHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'DBDDE2',
        margin: theme.spacing(1),
    },
    dialogueBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Verdana',
        color: 'black',
        margin: theme.spacing(1),
        backgroundColor: 'DBDDE2',
    },
    dialogueLogo: {
        height: '120px',
        width: '100%',
    },
    dialogue: {
    },
}));


const FeedbackDialog = (props) => {
    const {fullScreen, open, handleClose, claimPub} = props;
    const classes = useStyles();
    const txt1 = useRef(null);
    const [feedbackText, setFeedbackText] = useState('');

    useEffect(() => {
        //
      }, []);

    const updateFeedbackText = (event) => {
        setFeedbackText(event.target.value);
    }

    const clickFeedbackText = (event) => {
        txt1.current.focus();
    }


    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.dialogue}
            PaperProps={{
                style: {
                  backgroundColor: '#DBDDE2',
                },
              }}
        >
            <DialogTitle id="responsive-dialog-title">
                Donate to the real {props.pubName}!
            </DialogTitle>
            <DialogContent>
                <React.Fragment>
                    <Typography variant={"body2"} className={classes.dialogueBody}>
                        Hello! {props.pubName} has not joined Pubmash, so it is not yet possible to donate to it.
                    </Typography>
                    <Typography variant={"body2"} className={classes.dialogueBody}>
                        If you own the real {props.pubName}, please get in touch with us below. We will take some extensive security steps to confirm
                        that you own the pub, and also discuss with you how you would like donations to be collected.
                    </Typography>

                    <TextField
                        label="Pub Owner:"
                        style={{ margin: 8 }}
                        placeholder="Enter your email and we will be in touch"
                        fullWidth
                        multiline
                        ref={txt1}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onClick={(event) => clickFeedbackText(event)}
                        onChange={(event) => updateFeedbackText(event)}
                    />
                </React.Fragment>
            </DialogContent>
            <DialogActions className={classes.dialogueHeader}>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button onClick={() => claimPub(feedbackText)}
                        variant={"contained"}
                        color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FeedbackDialog;