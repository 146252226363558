export const GetLevelFromExperience = (experience) => {
    return XpLevels.findIndex(number => number > experience) +1;
}

export const GetPercentageFromNextLevel = (experience) => {
    const currentLevel = XpLevels.findIndex(number => number > experience) +1;
    if(currentLevel >= XpLevels.length)
        return 100;
    
    const nextLevelXp = XpLevels[currentLevel + 1];
    
    return (experience / nextLevelXp) * 100;
}

const XpLevels = [
    180,
    540,
    1080,
    1620,
    2520,
    3240,
    4140,
    5220,
    6300,
    7380,
    8640,
    10080,
    11340,
    12960,
    14400,
    16020,
    17640,
    19260,
    21060,
    22860,
    24840,
    26820,
    28800,
    30780,
    32940,
    35100,
    37260,
    39600,
    41760,
    44100,
    46620,
    49140,
    51480,
    54180,
    56700,
    59400,
    62100,
    64800,
    67500,
    70380,
    73260,
    76140,
    79200,
    82080,
    85140,
    88200,
    91440,
    94500,
    97740,
    100980,
    104220,
    107640,
    111060,
    114300,
    117900,
    121320,
    124920,
    128340,
    131940,
    135720,
    139320,
    143100,
    146700,
    150480,
    154440,
    158220,
    162180,
    166140,
    170100,
    174060,
    178020,
    182160,
    186300,
    190440,
    194580,
    198900,
    203040,
    207360,
    211680,
    216000,
    220500,
    224820,
    229320,
    233820,
    238320,
    242820,
    247500,
    252000,
    256680,
    261360,
    266040,
    270900,
    275580,
    280440,
    285300,
    290160,
    295020,
    300060,
    304920,
    309960,
    315000,
    320040,
    325260,
    330300,
    335520,
    340560,
    345780,
    351180,
    356400,
    361620,
    367020,
    372420,
    377820,
    383220,
    388620,
    394200,
    399600,
    405180,
    410760,
    416340,
    421920,
    427680,
    433260,
    439020,
    444780,
    450540,
    456300,
    462240,
    468000,
    473940,
    479880,
    485820,
    491760,
    497700,
    503820,
    509760,
    515880,
    522000,
    528120,
    534240,
    540540,
    546660,
    552960,
    559260,
    565560,
    571860,
    578160,
    584640,
    590940,
    597420,
    603900,
    610380,
    616860,
    623340,
    630000,
    636480,
    643140,
    649800,
    656460,
    663120,
    669960,
    676620,
    683460,
    690120,
    696960,
    703800,
    710640,
    717660,
    724500,
    731520,
    738540,
    745560,
    752580,
    759600,
    766620,
    773640,
    780840,
    788040,
    795240,
    802440,
];