import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles/";
import {auth} from "./helpers/firebase";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import {CircularProgress} from "@material-ui/core";
import {GetLevelFromExperience, GetPercentageFromNextLevel} from "./helpers/expToLevel";
import SettingsIcon from '@material-ui/icons/Settings';
import FlagIcon from '@material-ui/icons/Flag';
import GroupIcon from '@material-ui/icons/Group';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {StyledBadge} from "./components/StyledBadge";
import Popover from "@material-ui/core/Popover";
import FriendCard from "./components/FriendsCard";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Badge from "@material-ui/core/Badge";
import PubCard from "./components/PubCard";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        width: '450px',
        height: '100vh',
        backgroundColor: '#F2F2F2',
        zIndex: 401,
        boxShadow: '2px 0px 10px rgba(7, 2, 10, 0.25)',
        transition: 'width 150ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '64px',
        },
    },
    profileContainer: {
        position: 'relative',
        backgroundColor: '#330F1B',
        height: '200px',
        width: '100%',
        borderBottom: '4px solid #D3C488',
        borderRight: '4px solid #D3C488',
        borderBottomRightRadius: '20px',
        padding: '30px',
        transition: 'all 150ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            borderBottom: 'none',
            borderRight: 'none',
            borderBottomRightRadius: '0px',
            padding: '10px 0px 0px 0px',
        },
    },
    profileLoggedIn: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0px 0px 25px 0px',
        transition: 'all 150ms ease-in-out',
        transform: "scale(1) !important",
        [theme.breakpoints.down('sm')]: {
            margin: '0px 0px 10px 0px',
            transform: "scale(0.8) !important",
        },
    },
    profileLoggedOut: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0px 0px 10px 0px',
        transition: 'all 150ms ease-in-out',
        transform: "scale(1) !important",
        [theme.breakpoints.down('sm')]: {
            margin: '0px 0px 10px 0px',
            transform: "scale(0.8) !important",
        },
    },
    profileAvatarContainer: {
        position: 'relative',
        height: '60px', 
        width: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    profileProgressBackground: {
        position: 'absolute',
        color: 'rgba(255, 255, 255, 0.2)',
    },
    profileProgress: {
        borderRadius: '50%',
        position: 'absolute',
        color: '#D3C488',
        transform: "rotate(-90deg) !important",
    },
    profileProgressCircle: {
        strokeLinecap: 'round',
    },
    profileAvatar: {
        position: 'absolute',
        height: '45px',
        width: '45px'
    },
    profileName: {
        display: 'block', 
        fontSize: '24px', 
        color: '#FFF'
    },
    profileLevel: {
        display: 'block', 
        fontSize: '14px',
        color: '#FFF'
    },
    profileMidColumn: {
        flex: 'auto',
        margin: 'auto 20px',
    },
    settingsIconButton: {
        color: 'rgba(255,255,255,0.5)',
        transition: 'color 150ms linear',
        '&:hover': {
            color: 'rgba(255,255,255,1)',
        }
    },
    loggedInButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '10px 0px',
        transition: 'all 150ms linear',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: '0px',
        },
    },
    profileButton: {
        transition: 'all 150ms linear',
        [theme.breakpoints.down('sm')]: {
            width: '40px',
            minWidth: '40px',
            margin: '5px auto',
        },
    },
    popover: {
        maxHeight: '400px',
        padding: '5px 10px',
    },
    pubsContainer: {
        padding: '20px',
        height: 'calc(100% - 200px)',
        overflow: 'auto',
    },
    pubContainerSettingsRow: {
        display: 'flex', 
        alignItems: 'center',
        position: 'relative',
    },
    barIcon: {
        margin: '0px 20px 0px 0px'
    },
    filterListContainer: {
        flex: 'auto',
    },
    filterList: {
        paddingRight: '70px !important',
    },
    filterListIcon: {
        padding: '5px 0px',
    },
    searchButton: {
    },
    searchPopup: {
        position: "absolute", 
        width: "408px", 
        height: "48px", 
        background: "#FFF", 
        borderRadius: "30px",
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px 0px 20px',
    },
    searchInput: {
        flex: 'auto',
    },
    closeSearchButton: {
    },
    tosStatement: {
        color: '#e2e2e2',
    },
    whatIsPubmash: {
        color: 'rgba(255,255,255,0.5)',
        textDecoration: 'underline',
        fontSize: '14px',
        cursor: 'pointer',
        '&:hover': {
            color: 'rgba(255,255,255,1)',
        }
    },
}));

export default function Sidebar(props) {
    const classes = useStyles();
    const theme = useTheme();
    const {user, friends, pubs, popularPubs, activePub, selectPub, setOpenWhatIs, login, logout} = props;

    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [pubFilter, setPubFilter] = useState('nearby');
    const [searchInput, setSearchInput] = useState('');
    const [searchBarOpen, setSearchBarOpen] = useState(false);
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const [missionsAnchorEl, setMissionsAnchorEl] = useState(null);
    const [friendsAnchorEl, setFriendsAnchorEl] = useState(null);

    const handleSettingsClick = (event) => {
        handleFriendsClose();
        handleMissionsClose();
        
        setSettingsAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsAnchorEl(null);
    };

    const handleFriendsClick = (event) => {
        handleSettingsClose();
        handleMissionsClose();
        
        setFriendsAnchorEl(event.currentTarget)
    }

    const handleFriendsClose = () => {
        setFriendsAnchorEl(null);
    };

    const handleMissionsClick = (event) => {
        handleSettingsClose();
        handleFriendsClose();
        
        setMissionsAnchorEl(event.currentTarget);
    };

    const handleMissionsClose = () => {
        setMissionsAnchorEl(null);
    };
    
    const signOut = (event) => {
        logout();
        handleSettingsClose();
    }
    
    function handlePubFilterChange(event) {
        setPubFilter(event.target.value);
    }

    useEffect(() => {
        
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.profileContainer}>
                {
                    auth.currentUser ?
                        <div className={classes.profileLoggedIn}>
                            <StyledBadge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                variant="dot"
                            >
                                <div className={classes.profileAvatarContainer}>
                                    <CircularProgress className={classes.profileProgressBackground}
                                                      value={100}
                                                      size={60}
                                                      thickness={5}
                                                      variant={"static"}
                                    />
                                    <CircularProgress className={classes.profileProgress}
                                                      value={GetPercentageFromNextLevel(user && user.xp ? user.xp : 0)}
                                                      size={60}
                                                      thickness={5}
                                                      variant={"static"}
                                                      classes={{
                                                          circleStatic: classes.profileProgressCircle
                                                      }}
                                    />
                                    <Avatar
                                        className={classes.profileAvatar}
                                        src={auth.currentUser && auth.currentUser.photoURL}
                                    />
                                </div>
                            </StyledBadge>
                            {
                                !matchesSmDown &&
                                <div className={classes.profileMidColumn}>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.profileName}
                                        color="textPrimary"
                                    >
                                        {auth.currentUser && auth.currentUser.displayName}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.profileLevel}
                                        color="textPrimary"
                                    >
                                        {`LVL ${user && user.xp ? GetLevelFromExperience(user.xp) : "0"} PUNTER`}
                                    </Typography>
                                </div>
                            }
                            { 
                                !matchesSmDown &&
                                <IconButton onClick={handleSettingsClick}
                                            className={classes.settingsIconButton}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            }
                            <Menu
                                id="simple-menu"
                                anchorEl={settingsAnchorEl}
                                keepMounted
                                open={Boolean(settingsAnchorEl)}
                                onClose={handleSettingsClose}
                            >
                                {/*<MenuItem onClick={handleSettingsClose}>Profile</MenuItem>*/}
                                {/*<MenuItem onClick={handleSettingsClose}>My account</MenuItem>*/}
                                <MenuItem onClick={signOut}>Sign out</MenuItem>
                            </Menu>
                        </div>
                        :
                        <div>
                            <Button onClick={event => login()}
                                    variant="contained"
                                    fullWidth
                                    className={classes.profileLoggedOut}
                            >
                                Sign in
                            </Button>
                            <Typography variant={"caption"} className={classes.tosStatement}>
                                By using Pubmash you also agree with our latest terms of service and privacy policy, which you can find 
                                <a href={"https://docs.google.com/document/d/1AjYRM-sKx9ETUR7Fg3u4R__gQckmaOPIJY1Rs6EY_xE/edit#heading=h.25gt0coc7b4h"} target="_blank" className={classes.tosStatement}> here.</a>
                            </Typography>
                        </div>
                }
                {
                    auth.currentUser &&
                    <div className={classes.loggedInButtons}>
                        <Button className={classes.profileButton}
                                variant={"outlined"}
                                size={"large"}
                                onClick={handleMissionsClick}
                        >
                            <FlagIcon />
                            {
                                !matchesSmDown &&
                                "Missions"
                            }
                        </Button>
                        <Badge color="primary" badgeContent={friends ? friends.filter(x => x.online).length : 0}>
                            <Button className={classes.profileButton}
                                    variant={"outlined"}
                                    size={"large"}
                                    onClick={handleFriendsClick}
                            >
                                <GroupIcon />
                                {
                                    !matchesSmDown &&
                                    "Friends"
                                }
                            </Button>
                        </Badge>
                    </div>
                }
                <Popover
                    id={"missions"}
                    open={Boolean(missionsAnchorEl)}
                    anchorEl={missionsAnchorEl}
                    onClose={handleMissionsClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.popover}>
                        <Typography variant={"h6"}>
                            Missions are coming soon...
                        </Typography>
                    </div>
                </Popover>
                <Popover
                    id={"friends"}
                    open={Boolean(friendsAnchorEl)}
                    anchorEl={friendsAnchorEl}
                    onClose={handleFriendsClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.popover}>
                    {
                        auth.currentUser && friends && friends.sort((a, b) => {
                            // equal items sort equally
                            if (a.lastActive === b.lastActive) {
                                return 0;
                            }
                            // nulls sort after anything else
                            else if (a.lastActive == null) {
                                return 1;
                            }
                            else if (b.lastActive == null) {
                                return -1;
                            }
                            
                            return moment(b.lastActive) - moment(a.lastActive);
                        }).map((friend, index) => (
                            <Grid item xs={12} key={index}>
                                <FriendCard user={friend} />
                            </Grid>
                        ))
                    }
                    </div>
                </Popover>
                {
                    !matchesSmDown &&
                    <Typography align={"center"} className={classes.whatIsPubmash} onClick={setOpenWhatIs}>
                        What is pubmash?
                    </Typography>
                }
            </div>
            <div className={classes.pubsContainer}>
                {
                    !matchesSmDown && 
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.pubContainerSettingsRow}>
                            <LocalBarIcon className={classes.barIcon}/>
                            <div className={classes.filterListContainer}>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        autoWidth
                                        value={pubFilter}
                                        onChange={handlePubFilterChange}
                                        displayEmpty
                                        name="pubFilter"
                                        IconComponent={FilterListIcon}
                                        classes={{
                                            select: classes.filterList,
                                            icon: classes.filterListIcon
                                        }}
                                    >
                                        <MenuItem value={"nearby"}>Nearby pubs</MenuItem>
                                        <MenuItem value={"popular"}>Popular pubs</MenuItem>
                                        {/*<MenuItem value={"favourite"}>Favourite pubs</MenuItem>*/}
                                    </Select>
                                </FormControl>
                            </div>
                            <IconButton className={classes.searchButton}
                                        onClick={event => setSearchBarOpen(true)}
                            >
                                <SearchIcon />
                            </IconButton>
                            {
                                searchBarOpen &&
                                <div className={classes.searchPopup}>
                                    <InputBase
                                        autoFocus={true}
                                        placeholder={"Search for a pub"}
                                        value={searchInput}
                                        onChange={event => setSearchInput(event.target.value)}
                                        className={classes.searchInput}
                                    />
                                    <IconButton className={classes.closeSearchButton}
                                                onClick={event => setSearchBarOpen(false)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            }
                        </Grid>
                        {
                            !pubs || pubs.length == 0 &&
                            <Grid item xs={12}>
                                <Typography variant={"body1"}>
                                    No pubs in the area, try moving the map or search for your favourite pub.
                                </Typography>
                            </Grid>
                        }
                        {
                            pubFilter == "nearby" && pubs && pubs.filter(x => (searchBarOpen && searchInput.length > 0) ? x.properties.name.toLowerCase().includes(searchInput.toLowerCase()) : true).sort((a, b) => a.properties.name - b.properties.name).map((pub, index) => (
                                <Grid item xs={12} key={index} >
                                    <PubCard
                                        pub={pub}
                                        user={user}
                                        friends={friends}
                                        activePub={activePub}
                                        selectPub={selectPub}
                                    />
                                </Grid>
                            ))
                        }
                        {
                            pubFilter == "popular" && popularPubs && popularPubs.filter(x => (searchBarOpen && searchInput.length > 0) ? x.properties.name.toLowerCase().includes(searchInput.toLowerCase()) : true).sort((a, b) => a.userCount - b.userCount).map((pub, index) => (
                                <Grid item xs={12} key={index} >
                                    <PubCard
                                        pub={pub}
                                        user={user}
                                        friends={friends}
                                        activePub={activePub}
                                        selectPub={selectPub}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                }
            </div>
        </div>
    );
}