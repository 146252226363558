import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import {StyledBadge} from "./StyledBadge";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ReactComponent as PubSign} from '../assets/PubSign.svg';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import Grid from "@material-ui/core/Grid";
import {auth} from "../helpers/firebase";
import IconButton from "@material-ui/core/IconButton";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import GroupIcon from "@material-ui/icons/Group";
import {wasOnlineSinceDuration} from "../helpers/helpers";

const useStyles = makeStyles(theme => ({
    gridContainer: {
        position: 'relative',
        padding: '10px 50px',
        height: '100%',
    },
    flexCentered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    pubName: {
        fontSize: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    pubCardActive: {
        width: '100%',
        height: '120px',
        position: 'relative',
        cursor: 'pointer',
        transition: '150ms all linear',
        color: '#FFFFFF',
        filter: 'drop-shadow(0px 5px 0px rgba(178, 64, 57, 1))',
    },
    pubCard: {
        cursor: 'pointer',
        transition: '150ms all linear',
        width: '100%',
        height: '120px',
        color: '#FFFFFF',
        position: 'relative',
        filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))',
        '&:hover': {
            filter: 'drop-shadow(0px 5px 0px rgba(178, 64, 57, 1))',
            // background:'#eaeaea',
            // filter: 'drop-shadow(0px 5px 0px rgba(0, 0, 0, .5))',
            // boxShadow: '0 5px 15px rgba(0,0,0,0.25)',
        }
    },
    pubCardBackground: {
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    pubCardBackgroundActive: {
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    popupBottomRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    popupBottomRowFavouriteButton: {
        color: '#FFFFFF',
        padding: '0px',
        marginRight: '10px',
        '&:hover': {
            color: '#EE1522',
        },
    },
    popupBottomRowPubCount: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    popupAvatar: {
        margin: '0px 0px 0px 5px',
        border: '2px solid #FFFFFF',
    },
    pubUndiscovered: {
        color: '#FFE600',
        fontSize: '18px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
}));

const PubCard = (props) => {
    const {pub, activePub, selectPub, user, friends} = props;
    const classes = useStyles();
    
    let friendStatus = "Not logged in";

    if (user && user.online) {
        friendStatus = user.pubName
            ? `Last seen in ${user.pubName.substring(0, 3) == "The" ? "" : "The"} ${user.pubName}`
            : `Not in a pub`;
    }
    else if(user && user.lastActive) {
        friendStatus = `Last active ${moment(user.lastActive).fromNow()}`;
    }

    const friendsInPubText = (friends, pub) => {
        const friendsInPub = friends.filter(x => x.pubId == pub.id.toString().replace('/', ''));
        let returnText = "";

        if(friendsInPub.length > 0) {
            friendsInPub.map((friend, index) => {
                if(index > 0 && friendsInPub.length > 1)
                    returnText += ", ";

                returnText += friend.name
            })

            returnText += " is in the pub!"
        }

        return returnText;
    };

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div onClick={() => selectPub(pub)} className={(activePub && activePub.id == pub.id) ? classes.pubCardActive : classes.pubCard}>
                <PubSign className={(activePub && activePub.id == pub.id) ? classes.pubCardBackgroundActive : classes.pubCardBackground}/>
                <Grid container className={classes.gridContainer} alignContent={"center"}>
                    <Grid item xs={12} className={classes.flexCentered}>
                        <Typography variant="h6" className={classes.pubName} >
                            {pub.properties ? pub.properties.name : pub.name}
                        </Typography>
                    </Grid>
                    {
                        !pub.discoveredBy &&
                        <Grid item xs={12} className={classes.flexCentered}>
                            <Typography variant="body2" className={classes.pubUndiscovered}>
                                {"Pub is undiscovered!"}
                            </Typography>
                        </Grid>
                    }
                    {
                        pub.discoveredBy && auth.currentUser && friends.length > 0 &&
                        <Grid item xs={12}>
                            <div className={classes.avatar}>
                                {
                                    friends.map((friend, index) => (
                                        friend.lastActive && wasOnlineSinceDuration(friend.lastActive, 30, 'minutes') && friend.pubId === activePub.id.toString().replace('/', '') &&// this seems to cause the pub is empty to appear even though it has nothing to do with that area of code
                                        <Avatar className={classes.popupAvatar} key={index}
                                                src={'https://graph.facebook.com/' + friend.facebookUid + '/picture'}
                                        />
                                    ))
                                }
                            </div>
                        </Grid>
                    }
                    {
                        pub.discoveredBy &&
                        <Grid item xs={12} className={classes.popupBottomRow}>
                            <Typography>
                                {/*<IconButton className={classes.popupBottomRowFavouriteButton}>*/}
                                {/*    <FavoriteBorderIcon />*/}
                                {/*</IconButton>*/}
                                {/*0 favourites*/}
                            </Typography>
                            <Typography className={classes.popupBottomRowPubCount}>
                                <GroupIcon style={{marginRight: '10px'}} />
                                {pub.users ? pub.users.length : 0} / {pub.userLimit ? pub.userLimit : 0}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </div>
    );
}

export default PubCard;

