import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles/";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {firestore, auth, fbAuthProvider, googleAuthProvider} from './helpers/firebase'
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import MaterialTable from "material-table";
import Card from "@material-ui/core/Card";
import axios from "axios";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    selectedPubList: {
        backgroundColor:'#f5f5f5'
    },
    invisibleListItem: {
        visibility: "hidden"
    }
}));

export default function App() {
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [pubs, setPubs ] = React.useState([])
    const history = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if(user) {
                auth.currentUser.getIdTokenResult()
                    .then((idTokenResult) => {
                        if (!!idTokenResult.claims.admin) {
                            getData();
                            setIsAdmin(true);
                        } else {
                            setIsAdmin(false);
                        }
                    })
                    .catch((error) => {
                        setIsAdmin(false)
                        setUsers([])
                        setPubs([])
                        console.log(error)
                    });
            } else {
                setIsAdmin(false)
                setUsers([])
                setPubs([])
            }
        })
    }, []);

    const getData = () => {
        const puntersRef = firestore.collection("punters");
        puntersRef.get()
            .then(querySnapshot => {
                let userCollection = []

                querySnapshot.forEach(doc => {
                    userCollection.push(doc.data())
                });
                
                setUsers(userCollection);
            })
            .catch(error => {
                console.log(error)
            });

        const pubsRef = firestore.collection("pubs");
        pubsRef.get()
            .then(querySnapshot => {
                let pubsCollection = []

                querySnapshot.forEach(doc => {
                    pubsCollection.push(doc.data())
                });

                setPubs(pubsCollection)
            })
            .catch(error => {
                console.log(error)
            });
    };

    const handleClick = (pubId) => {
        history.push("/?pubid="+pubId);
    }

    const login = () => {
        auth.signInWithPopup(fbAuthProvider)
            .then(loginResult => {
                var token = loginResult.credential.accessToken;
                var user = loginResult.user;

                const userRef = firestore.collection("punters").doc(user.uid);

                userRef.set({
                    displayName: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    photoURL: user.photoURL,
                    refreshToken: user.refreshToken,
                    lastActive: Date.now(),
                }, {merge: true})
                    .then(createUserDocResult => {
                        user.providerData.forEach(userInfo => {
                            switch (userInfo.providerId) {
                                case "facebook.com":
                                    userRef.set({
                                        facebookUid: userInfo.uid,
                                    }, {merge: true});

                                    axios.get(`https://graph.facebook.com/v2.11/${userInfo.uid}/?fields=friends{name,id}&access_token=${token}`)
                                        .then(friendsResult => {
                                            return friendsResult.data.friends.data.forEach(friend => {
                                                return userRef.collection("facebookFriends").doc(friend.id).set({
                                                    name: friend.name,
                                                    facebookUid: friend.id,
                                                    lastUpdated: Date.now(),
                                                }, {merge: true});
                                            })
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            return null;
                                        })
                                    break;
                                case "google.com":
                                    break;
                                default:
                                    console.log(`user is signed in with ${userInfo.providerId}`)
                                    break;
                            }
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log(error)
        });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Card>
                    {
                        !auth.currentUser &&
                        <Button onClick={() => login()} variant="contained" color="primary">
                            Sign in
                        </Button>
                    }
                    {
                        auth.currentUser && isAdmin &&
                        <React.Fragment>
                            <MaterialTable
                                title={"Pub activity"}
                                columns={[
                                    {
                                        field: 'name',
                                        title: "Name"
                                    },
                                    {
                                        field: 'users',
                                        title: "Visitors",
                                        render: rowData => rowData.users && rowData.users.length > 0 ? `${rowData.users.length} users right now` : "No users"
                                    },
                                ]}
                                data={pubs.sort((a, b) => (a.currentVisitors < b.currentVisitors) ? 1 : -1)}
                                actions={[
                                    {
                                        icon: 'search',
                                        tooltip: 'Visit pub',
                                        onClick: (event, rowData) => handleClick(rowData.id),
                                    }
                                ]}
                                options={{
                                    pageSize: 20,
                                }}
                            />
                        </React.Fragment>
                    }
                </Card>
            </Grid>

            <Grid item xs={6}>
                <Card>
                    {
                        auth.currentUser && isAdmin &&
                        <React.Fragment>
                            <MaterialTable
                                title={"User activity"}
                                columns={[
                                    {
                                        field: 'displayName',
                                        title: "Display Name"
                                    },
                                    {
                                        field: 'geohash',
                                        title: "Geohash"
                                    },
                                    {
                                        field: 'pubId',
                                        title: "Latest PubId",
                                        render: rowData => rowData.pubId ? rowData.pubName : "Not in a pub"
                                    },
                                    {
                                        field: 'lastActive',
                                        title: "Last Active",
                                        render: (rowData) => rowData.lastActive ? moment(rowData.lastActive).fromNow() : 'No date found',
                                    },
                                ]}
                                data={users.sort((a, b) => (a.displayName < b.displayName) ? 1 : -1)}
                                actions={[
                                    {
                                        icon: 'search',
                                        tooltip: 'Visit pub',
                                        onClick: (event, rowData) => handleClick(rowData.pubId),
                                    }
                                ]}
                                options={{
                                    pageSize: 20,
                                }}
                            />
                        </React.Fragment>
                    }
                </Card>
            </Grid>
        </Grid>
    );
}