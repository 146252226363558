import React, { useState, useEffect, useRef } from "react";
import {Map, Marker, Popup, TileLayer, GeoJSON, LayerGroup, withLeaflet, ZoomControl} from "react-leaflet";
import { Icon } from "leaflet";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { makeStyles } from "@material-ui/core/styles/";
import * as overpass from "query-overpass";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { SearchControl, OpenStreetMapProvider } from 'react-leaflet-geosearch'
import {firestore, auth, database, analytics, functions, fbAuthProvider, googleAuthProvider} from './helpers/firebase'
import Geohash from 'latlon-geohash';
import MarkerClusterGroup from "react-leaflet-markercluster";
import Unity, { UnityContent } from "react-unity-webgl";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams, useLocation } from "react-router-dom";
import Tab from '@material-ui/core/Tab';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/styles";
import clsx from "clsx";
import moment from "moment";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import axios from "axios";
import WhatIsPubMashDialog from "./components/WhatIsPubMashDialog";
import ClaimPub from "./components/ClaimPub";
import FeedbackDialog from "./components/FeedbackDialog";
import {wasOnlineSinceDuration} from "./helpers/helpers";
import Sidebar from "./Sidebar";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GroupIcon from '@material-ui/icons/Group';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const pubIcon = new Icon({
    iconUrl: "./pubIcon.png",
    iconSize: [75, 75]
});

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    avatar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonEnter: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '3px 20px',
        margin: '15px auto',
        textAlign: 'center',
        color: '#FFE600',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(255, 229, 0, 0.5)',
        boxSizing: 'border-box',
        border: '2px solid #FFE600',
        background: 'none',
        transition: '150ms all linear',
        fontSize: '20px',
        '&:hover': {
            backgroundColor: '#FFE600',
            color: '#330F1B',
        },
        '&:disabled': {
            border: 'none',
            boxShadow: 'none',
            background: 'none',
            color: '#FFE600',
        }
    },
    popupInviteText: {
        color: '#FFFFFF',
    },
    popupInviteAvatar: {
        margin: '0px auto 10px auto',
        border: '2px solid #FFFFFF',
    },
    popupAvatar: {
        margin: '0px 0px 0px 5px',
        border: '2px solid #FFFFFF',
    },
    markerclusterMapOpen: {
        width: '1000px'
    },
    markerclusterMapClosed: {
        marginLeft:'0px',
        width: '100%',
    },
    spacer: {
        margin: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    markerclusterMap: {
        width: '100px'
    },
    pubList: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 550,
        backgroundColor: '#dbdde2',
        height: '100vh',
        maxHeight: '100vh',
        overflow: 'auto',
        padding: '0px 10px',
        width: '428px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
        transition: '200ms all ease-out',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
    },
    pubListClosed: {
        left: '-428px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0)',
    },
    pubListHandle: {
        position: 'fixed',
        margin: 'auto',
        bottom: 0,
        top: 0,
        left: '428px',
        height: '150px',
        width: '50px',
        backgroundColor: '#dbdde2',
        transition: '200ms all ease-out',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
        cursor: 'pointer',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
        zIndex: 500,
    },
    pubListHandleClosed: {
        left: '0px',
    },
    pubListHandleText: {
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
        fontSize: '1.5em',
    },
    popup: {
        bottom: '15px !important',
        minWidth: '350px',
        "& div[class='leaflet-popup-content-wrapper']": {
            background: '#330F1B',
            border: '5px solid #D3C488',
        },
        "& div[class='leaflet-popup-tip-container']": {
            display: 'none',
        },
        "& a[class='leaflet-popup-close-button']": {
            padding: '10px 25px 0px 0px',
        }
    },
    popupContainer: {
        padding: '10px',
    },
    popupBottomRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    popupBottomRowFavouriteButton: {
        color: '#FFFFFF',
        padding: '0px',
        marginRight: '10px',
        '&:hover': {
            color: '#EE1522',
        },
    },
    popupBottomRowPubCount: {
        display: 'flex',
        alignItems: 'center',
    },
    inline: {
        color: '#FFFFFF',
        pointerEvents: 'none',
    },
    pubName: {
        color: '#FFFFFF',
        fontSize: '24px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    pubEvent: {
        color: '#D3C488',
        fontSize: '18px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    pubUndiscovered: {
        color: '#FFE600',
        fontSize: '18px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    pubNumVisitors: {
        color: '#FFFFFF',
        fontSize: '1em',
    },
    pubFriends: {
        fontSize: '1em',
    },
    zoomControl: {
        left: '450px',
    },
    whatis: {
        paddingTop: '5px',
        display: 'flex',
        justifyContent: 'right',
        alignContent: 'right',
        textAlign: 'right',
    },
    betaTag: {
        backgroundColor: '#38A085',
        color: '#FFFFFF',
        transform: 'rotateZ(-45deg)',
        position: 'fixed', 
        height: '100px', 
        width: '400px', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1050', 
        bottom: '55px', 
        right: '-95px', 
        cursor: 'pointer',
        pointerEvents: 'auto',
        transition: '200ms all linear',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.15)',
        '&:hover': {
            backgroundColor: '#41a88c',
            boxShadow: '0 0 15px rgba(0, 0, 0, 0.4)',
        }
    },
    map: {
        marginLeft: '450px',
        transition: 'all 150ms ease-in-out',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '64px',
        },
    },
    pubIcon: {
        boxShadow: '0px 10px 10px rgba(31, 15, 51, 0.25)',
    },
}));

const App = (props) => {
    const classes = useStyles();

    const [isInsideBar, setIsInsideBar] = useState(null);
    const [previousBar, setPreviousBar] = useState(null);
    const [unityLoading, setUnityLoading] = useState(false);
    const [activePub, setActivePub] = useState(null);
    const [latitude, setLatitude] = useState(52.634085186724796)
    const [longitude, setLongitude] = useState(-1.1324286460876467)
    const [maxLongitude, setMaxLongitude] = useState(0)
    const [minLongitude, setMinLongitude] = useState(0)
    const [maxLatitude, setMaxLatitude] = useState(0)
    const [minLatitude, setMinLatitude] = useState(0)
    const [nearbyPubs, setNearbyPubs] = useState([])
    const [userGeohash, setUserGeohash] = useState()
    const [geohashSize , setGeohashSize] = useState(5)
    const [counter , setCounter] = useState(0)
    const [unityContent, setUnityContent] = useState(new UnityContent(
        "unity/WebGL.json",
        "unity/UnityLoader.js"
    ));
    const [showOnlyOccupied , setShowOnlyOccupied] = useState(false) // all users in pubs on the screen
    const [tabValue, setTabValue] = useState(0);
    const [friends, setFriends] = useState([]);
    const [user, setUser] = useState(null);
    const [open, setOpen] = useState(false)
    const [isEnteringPub, setIsEnteringPub] = useState(false)
    const [isCreatingInvite, setIsCreatingInvite] = useState(false)
    const [uDateTime, setUDateTime] = useState(null)
    const [movedUser, setMovedUser] = useState(false)
    const [refreshToken, setRefreshToken] = useState(null)
    const [popularPubs, setPopularPubs] = useState([])
    
    

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const { pubid } = useParams()
    let query = useQuery();

    // login variables
    const [viewport, setViewport ] = useState(null)
    const [geojson, setGeojson] = useState(null)
    const mymap = useRef(null);

    // dialogue stuff
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeStep, setActiveStep] = useState(0);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [claimFeedbackOpen, setClaimFeedbackOpen] = useState(false);
    

    //snackbar
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    // invitations
    const [invitationDetails, setInvitationDetails] = useState(null);


    const viewportChanged = (viewport) => {
        if(!mymap || !mymap.current)
            return;
        
        const latLonBounds = mymap.current.leafletElement.getBounds()
        setLatitude(viewport.center[0])
        setLongitude(viewport.center[1])
        setMinLongitude(latLonBounds._southWest.lng)
        setMaxLongitude(latLonBounds._northEast.lonlng)
        setMinLatitude(latLonBounds._southWest.lat)
        setMaxLatitude(latLonBounds._northEast.lat)
        getLocalPubs(viewport.center[0], viewport.center[1]);
    };
    const viewportChangedDebounced = AwesomeDebouncePromise(viewportChanged, 1000);

    useEffect(() => {
        setMenuIsOpen(!matches);
    }, [matches])

    useEffect(() => {
        if(previousBar) {
            moveUserToPub(previousBar.id);
            setMovedUser(true)
        }
    }, [previousBar])

    useEffect(() => {
        setUDateTime(Date.now())
        setViewport({
            center: [52.634085186724796, -1.1324286460876467],
            zoom: 16,
        })
        
        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords;
                setViewport({
                    center: [latitude, longitude],
                    zoom: 16,
                })
                getLocalPubs(latitude, longitude);
            }, error => {
                setViewport({
                    center: [52.634085186724796, -1.1324286460876467],
                    zoom: 16,
                })
                getLocalPubs(52.634085186724796, -1.1324286460876467);
            }
        );
    }, []);

    useEffect(() => {
        if(viewport && mymap && mymap.current)
        {
            // Look for nearby punters and store
            const geohash = Geohash.encode(mymap.current.leafletElement.getCenter().lat, mymap.current.leafletElement.getCenter().lng, geohashSize);
            if(query.get("invid")) {
                const invitationId = query.get("invid")
                var invitationsRef = firestore.collection("invitations").doc(invitationId);
                invitationsRef.get().then(function (docRef) {
                    const invitePubId = docRef.data()
                    if(!movedUser && invitePubId.invitationDate && wasOnlineSinceDuration(invitePubId.invitationDate, 1, 'days')) {
                        setInvitationDetails(invitePubId)
                        let tableId = query.get("t")
                        moveUserToPub(invitePubId.pubId);
                        setMovedUser(true)
                    }

                }).catch(
                    (error) => {console.log("error", error) }
                );
            }
            else if(query.get("pubid")) {
                let tableId = query.get("t")
                moveUserToPub(query.get("pubid"));

            }
        }
    }, [viewport]);

    useEffect(() => {
        if(viewport && mymap && mymap.current) {
            const geohash = Geohash.encode(mymap.current.leafletElement.getCenter().lat, mymap.current.leafletElement.getCenter().lng, geohashSize);
            const neighbours = Geohash.neighbours(geohash)

            //pubsRef.where('geohash', 'in', [geohash, neighbours['n'], neighbours['ne'], neighbours['e'], neighbours['se'], neighbours['s'], neighbours['sw'], neighbours['w'], neighbours['nw']])
            const latLonBounds = mymap.current.leafletElement.getBounds()
            setLatitude(viewport.center[0])
            setLongitude(viewport.center[1])
            setMinLongitude(latLonBounds._southWest.lng)
            setMaxLongitude(latLonBounds._northEast.lng)
            setMinLatitude(latLonBounds._southWest.lat)
            setMaxLatitude(latLonBounds._northEast.lat)

            if(userGeohash != geohash) {
                const query = '[out:json];node["amenity"~"pub|bar"]('+Geohash.decode(neighbours['sw']).lat+','+Geohash.decode(neighbours['sw']).lon+','+Geohash.decode(neighbours['ne']).lat+','+Geohash.decode(neighbours['ne']).lon+');out;';
                const options = {
                    flatProperties: true
                };
                overpass(query, dataHandler, options);
            } else {
                // do not need to get near OSM data, but still need to merge arrays to update model for the view
                if(geojson) {
                    let merged = [];
                    if(nearbyPubs) {
                        for(let i=0; i<geojson.length; i++) {
                            if(geojson[i].id) {
                                merged.push({
                                    ...geojson[i],
                                    ...(nearbyPubs.find((itmInner) => itmInner.id.toString().replace('/','') === geojson[i].id.toString().replace('/','')))}
                                );
                            }
                        }

                        if (geojson !== undefined) {
                            //shouldDisplay(pub.geometry.coordinates) && pub.properties && pub.properties.name &&
                            setGeojson(merged)
                        }
                    }
                    else if (geojson !== undefined) {

                    }
                }
            }
            setUserGeohash(geohash)
        }
    }, [nearbyPubs, counter]);

    useEffect(() => {
        let subscription = null;
        if(userGeohash) {
            const neighbours = Geohash.neighbours(userGeohash)
            if(subscription) {
                subscription();
            }
        
            subscription = firestore.collection("pubs").where('geohash', 'in', [userGeohash, neighbours['n'], neighbours['ne'], neighbours['e'], neighbours['se'], neighbours['s'], neighbours['sw'], neighbours['w'], neighbours['nw']])
                .onSnapshot(function(snapshot) {
                    let newArray = nearbyPubs
                    let date = Math.floor(Date.now() / 1000)
                    snapshot.docChanges().forEach(function(change) {
                        if (change.type === "added") {
                            var foundIndex = newArray.findIndex(x => x.id == change.doc.data().id);
                            if(foundIndex > -1) {
                                newArray[foundIndex] = change.doc.data();
                            }
                            else {
                                newArray.push(change.doc.data());
                            }
                            setNearbyPubs(newArray)
                            setCounter("ADDED"+date)
                        }
                        if (change.type === "modified") {
                            var foundIndex = newArray.findIndex(x => x.id == change.doc.data().id);
                            if(foundIndex > -1) {
                                newArray[foundIndex] = change.doc.data();
                            }
                            else {
                                newArray.push(change.doc.data());
                            }
                            setNearbyPubs(newArray)
                            setCounter("MODIFIED"+date)
                        }
                        if (change.type === "removed") {
                            // console.log("removed")
                        }
                    });
                });
            return () => {
                if(subscription) {
                    subscription();
                }
            };
        }
    }, [userGeohash]);


    // Subscriptions
    useEffect(() => {
        let callbackSubscription = null;
        let metadataRef = null;
        let friendsSubscription = null;
        let userSubscription = null;
        let popularPubsSubscription = null;

        const subscription = auth.onAuthStateChanged(firebaseUser => {
            if (callbackSubscription) {
                metadataRef.off('value', callbackSubscription);
            }

            if (firebaseUser) {
                friendsSubscription = firestore.collection("punters").doc(firebaseUser.uid).collection("facebookFriends")
                    .onSnapshot(snapshot => {
                        let friendsCopy = friends;
                        snapshot.docChanges().forEach(change => {
                            if (change.type === "added") {
                                const friendData = change.doc.data();
                                const existingFriendIndex = friendsCopy.findIndex(x => x.facebookUid == friendData.facebookUid);

                                if (existingFriendIndex >= 0) {
                                    friendsCopy[existingFriendIndex] = friendData;
                                } else {
                                    friendsCopy.push(friendData);
                                }
                            }
                            if (change.type === "modified") {
                                const friendData = change.doc.data();
                                const existingFriendIndex = friendsCopy.findIndex(x => x.facebookUid == friendData.facebookUid);

                                if (existingFriendIndex >= 0) {
                                    friendsCopy[existingFriendIndex] = friendData;
                                } else {
                                    friendsCopy.push(friendData);
                                }
                            }
                            if (change.type === "removed") {
                                friendsCopy = friendsCopy.filter(x => x.facebookUid != change.doc.data());
                            }
                        });

                        setFriends(friendsCopy);
                    }, error => {
                        console.log(error);
                    });

                const puntersRef = firestore.collection('punters'); // Get a reference to the Users collection;
                const userOnlineStatusRef = database.ref('.info/connected'); // Get a reference to the list of connections

                leavePub();

                userOnlineStatusRef.on('value', snapshot => {
                    database
                        .ref(`/status/${firebaseUser.uid}`)
                        .onDisconnect()
                        .update({online: false})
                        .then(() => {
                            // Set the Firestore User's online status to true
                            puntersRef
                                .doc(firebaseUser.uid)
                                .set({
                                    lastActive: Date.now(),
                                    online: true,
                                }, { merge: true});

                            database.ref(`/status/${firebaseUser.uid}`).update({online: true});
                        });
                });

                userSubscription = puntersRef.doc(firebaseUser.uid)
                    .onSnapshot(snapshot => {
                        if(snapshot.exists) {
                            let userData = snapshot.data();
                            if(userData) {
                                userData.uid = firebaseUser.uid;
                                setUser(userData);

                                unityContent.send(
                                    "GameManager",
                                    "UserUpdated",
                                    JSON.stringify(userData)
                                );
                            }
                        }
                    }, error => {
                        console.log(error);
                    })

                metadataRef = database.ref('status/' + firebaseUser.uid + '/refreshTime');
                callbackSubscription = () => {
                    firebaseUser.getIdToken(true);
                };
                metadataRef.on('value', callbackSubscription);
            }
        })

        const idTokenChangedSubscription = auth.onIdTokenChanged((user) => {
            if (user) {
                setRefreshToken(user.refreshToken);
                
                auth.currentUser.getIdToken(true).then((idToken) => {
                    const tokens = {
                        idToken: idToken,
                        refreshToken: refreshToken,
                    };
                    
                    unityContent.send(
                        "GameManager",
                        "FirebaseTokensUpdate",
                        JSON.stringify(tokens)
                    );
                }).catch((error) => {
                    console.log(error);
                });
            }
        });

        popularPubsSubscription = firestore.collection("pubs").where("userCount", ">", 0).orderBy("userCount", "desc").limit(25)
            .onSnapshot(snapshot => {
                let popularPubsCopy = [];
                snapshot.forEach(doc => {
                    popularPubsCopy.push(doc.data());
                });
                setPopularPubs(popularPubsCopy);
            });

        return () => {
            if(subscription)
                subscription();
            if(userSubscription)
                userSubscription();
            if(friendsSubscription)
                friendsSubscription();
            if(idTokenChangedSubscription)
                idTokenChangedSubscription();
            if(popularPubsSubscription)
                popularPubsSubscription();
        };
    }, []);
    
    useEffect(() => {
        let tableSubscription = null;

        if(user && user.tableCode) {
            if(tableSubscription)
                tableSubscription();

            tableSubscription = firestore.collection("pubs").doc(user.pubId).collection("insidePub").doc(user.tableId).collection("private").doc("private")
                .onSnapshot(snapshot => {
                    //console.log(snapshot.data())

                    if(isInsideBar) {
                        unityContent.send(
                            "Table Request Panel",
                            "TableRequestsUpdated",
                            JSON.stringify(snapshot.data().usersRequestedAccess)
                        );
                    }
                }, error => {
                    console.log(error);
                })
        }
        
        return () => {
            if(tableSubscription)
                tableSubscription();
        }
    }, [user])

    const dataHandler = (error, osmData) => {
        if(osmData && osmData.features) {
            let merged = [];
            if(nearbyPubs) {
                for(let i=0; i<osmData.features.length; i++) {
                    if(osmData.features[i].id) {
                        merged.push({
                            ...osmData.features[i],
                            ...(nearbyPubs.find((itmInner) => itmInner.id.toString().replace('/','') === osmData.features[i].id.toString().replace('/','')))}
                        );
                    }
                }
                if (!error && osmData.features !== undefined) {
                    setGeojson(merged)
                }
            }
            else if (!error && osmData.features !== undefined) {
                setGeojson(osmData)
            }
        }
    };

    const onViewportChanged = async (viewport) => {
        await viewportChangedDebounced(viewport);
    }
    
    const loginWithGoogle = () => {
        auth.signInWithPopup(googleAuthProvider)
            .then(loginResult => {
                console.log(loginResult)
                var token = loginResult.credential.accessToken;
                var user = loginResult.user;

                const userRef = firestore.collection("punters").doc(user.uid);
                setRefreshToken(user.refreshToken);
                
                userRef.set({
                    displayName: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    photoURL: user.photoURL,
                    refreshToken: user.refreshToken,
                    lastActive: Date.now(),
                }, {merge: true})
                    .then(createUserDocResult => {
                        user.providerData.forEach(userInfo => {
                            switch (userInfo.providerId) {
                                case "facebook.com":
                                    userRef.set({
                                        facebookUid: userInfo.uid,
                                    }, {merge: true});

                                    axios.get(`https://graph.facebook.com/v2.11/${userInfo.uid}/?fields=friends{name,id}&access_token=${token}`)
                                        .then(friendsResult => {
                                            return friendsResult.data.friends.data.forEach(friend => {
                                                return userRef.collection("facebookFriends").doc(friend.id).set({
                                                    name: friend.name,
                                                    facebookUid: friend.id,
                                                    lastUpdated: Date.now(),
                                                }, {merge: true});
                                            })
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            return null;
                                        })
                                    break;
                                case "google.com":
                                    break;
                                default:
                                    console.log(`user is signed in with ${userInfo.providerId}`)
                                    break;
                            }
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    });

                setTabValue(0);
            }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log(error)
        });
    }

    const loginWithFacebook = () => {
        auth.signInWithPopup(fbAuthProvider)
            .then(loginResult => {
                var token = loginResult.credential.accessToken;
                var user = loginResult.user;

                const userRef = firestore.collection("punters").doc(user.uid);
                setRefreshToken(user.refreshToken);
                
                userRef.set({
                    displayName: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    photoURL: user.photoURL,
                    refreshToken: user.refreshToken,
                    lastActive: Date.now(),
                }, {merge: true})
                    .then(createUserDocResult => {
                        user.providerData.forEach(userInfo => {
                            switch (userInfo.providerId) {
                                case "facebook.com":
                                    userRef.set({
                                        facebookUid: userInfo.uid,
                                    }, {merge: true});

                                    axios.get(`https://graph.facebook.com/v2.11/${userInfo.uid}/?fields=friends{name,id}&access_token=${token}`)
                                        .then(friendsResult => {
                                            return friendsResult.data.friends.data.forEach(friend => {
                                                return userRef.collection("facebookFriends").doc(friend.id).set({
                                                    name: friend.name,
                                                    facebookUid: friend.id,
                                                    lastUpdated: Date.now(),
                                                }, {merge: true});
                                            })
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            return null;
                                        })
                                    break;
                                case "google.com":
                                    break;
                                default:
                                    console.log(`user is signed in with ${userInfo.providerId}`)
                                    break;
                            }
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    });

                setTabValue(0);
            }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log(error)
        });
    }

    const logout = () => {
        auth.signOut().then(function() {
            setFriends([]);
        }, function(error) {
            console.error('Sign Out Error', error);
        });
    }

    const selectPub = (e) => {
        // console.log("settomg active", e)
        setActivePub(e);
    }
    
    let barId = "";

    const enterPub = (pub) => {
        if(auth.currentUser) {
            setIsEnteringPub(true);
            
            // create id from map node. 
            const id = pub.properties.id.toString().replace('/','')
            // create geohash
            const geohash = Geohash.encode(pub.geometry.coordinates[1], pub.geometry.coordinates[0], geohashSize);

            const enterPubFunction = functions.httpsCallable('enterPub');
            let enterPubData = {};
            enterPubData = {
                id: id,
                name: pub.properties.name,
                latitude: pub.geometry.coordinates[1],
                longitude: pub.geometry.coordinates[0],
                osm: pub,
                geohash: geohash,
            };

            enterPubFunction(enterPubData)
                .then(result => {
                    barId = id;
                    const pubInfo = {
                        id: id,
                        name: pub.properties.name
                    }
                    analytics.logEvent('entered_pub', {
                        pub_id: id,
                        pub_name:pub.properties.name,
                        hasInvitation: invitationDetails != null,
                    });

                    setIsInsideBar(pubInfo);
                    setIsEnteringPub(false);
                    setUnityLoading(true);
                    unityContent.on("loaded", () => {
                        onUnityLoaded(pubInfo);
                    });
                    unityContent.on("quitted", () => {
                        console.log("Quit Unity")
                    });
                    unityContent.on("error", message => {
                        console.log(message)
                    });
                    unityContent.on("JoinTable", joinTableJson => {
                        joinTable(joinTableJson)
                    });
                    unityContent.on("LeaveTable", leaveTableJson => {
                        leaveTable(leaveTableJson)
                    });
                    unityContent.on("AcceptUser", acceptUserJson => {
                        acceptUser(acceptUserJson)
                    });
                    unityContent.on("RejectUser", rejectUserJson => {
                        rejectUser(rejectUserJson)
                    });
                    unityContent.on("RequestBarUpdate", () => {
                        requestBarUpdate()
                    });
                })
                .catch(error => {
                    // Getting the Error details.
                    var code = error.code;
                    var message = error.message;
                    var details = error.details;
                    console.log(error);
                });
        }
    }
    
    const updateUserPosRot = (newPosRot) => {
        firestore.collection('punters').doc(auth.currentUser.uid)
            .set({
                posRot: newPosRot,
            }, {merge: true});
    };
    
    const requestBarUpdate = () => {
        if(barId)
        {
            unityContent.send(
                "GameManager",
                "UserUpdated",
                JSON.stringify(user)
            );
            
            auth.currentUser.getIdToken(true).then((idToken) => {
                const tokens = {
                    idToken: idToken,
                    refreshToken: refreshToken,
                };
                
                unityContent.send(
                    "GameManager",
                    "FirebaseTokensUpdate",
                    JSON.stringify(tokens)
                );
            }).catch((error) => {
                console.log(error);
            });
            
            firestore.collection("pubs").doc(barId).get()
                .then(snapshot => {
                    const pubData = snapshot.data();

                    unityContent.send(
                        "GameManager",
                        "BarUpdated",
                        JSON.stringify(pubData)
                    );
                })
                .catch(error => {
                    console.log(error);
                })

            firestore.collection("pubs").doc(barId).collection("insidePub").get()
                .then(querySnapshot => {
                    let insideBarData = {
                        general: null,
                        tables: [],
                    };
                    querySnapshot.forEach(doc => {
                        if(doc.id == "general") {
                            insideBarData.general = doc.data();
                            insideBarData.general.users = insideBarData.general.users.filter(x => x.uid != auth.currentUser.uid);
                        }
                        else {
                            const tableData = doc.data();
                            const chairs = [];
                            Object.keys(tableData).map(key => {
                                chairs.push({
                                    ...tableData[key],
                                    chairId: key,
                                })
                            })

                            insideBarData.tables.push({
                                tableId: doc.id,
                                chairs: chairs,
                            })
                        }
                    })

                    unityContent.send(
                        "GameManager",
                        "InsideBarUpdated",
                        JSON.stringify(insideBarData)
                    );
                });
        }
    };

    const leavePub = () => {
        if(!activePub || window.confirm("Are you sure you want to leave the pub?"))
        {
            const leavePub = functions.httpsCallable('leavePub');
            leavePub({})
                .then(result => {
                    var sanitizedMessage = result.data.text;
                })
                .catch(error => {
                    // Getting the Error details.
                    var code = error.code;
                    var message = error.message;
                    var details = error.details;
                    console.log(error);
                });
            setPreviousBar(isInsideBar)
            setIsInsideBar(null);
        }
    }

    const onUnityLoaded = (pubInfo) => {
        setUnityLoading(false);

        unityContent.send(
            "GameManager",
            "SetBarName",
            pubInfo.name
        );

        const pubSubscription = firestore.collection("pubs").doc(pubInfo.id)
            .onSnapshot(snapshot => {
                const pubData = snapshot.data();

                unityContent.send(
                    "GameManager",
                    "BarUpdated",
                    JSON.stringify(pubData)
                );
            });

        const insidePubSubscription = firestore.collection("pubs").doc(pubInfo.id).collection("insidePub")
            .onSnapshot(querySnapshot => {
                let insideBarData = {
                    general: null,
                    tables: [],
                };
                querySnapshot.forEach(doc => {
                    if(doc.id == "general") {
                        insideBarData.general = doc.data();
                        insideBarData.general.users = insideBarData.general.users.filter(x => x.uid != auth.currentUser.uid);
                    }
                    else {
                        const tableData = doc.data();
                        const chairs = [];
                        Object.keys(tableData).map(key => {
                            chairs.push({
                                ...tableData[key],
                                chairId: key,
                            })
                        })

                        insideBarData.tables.push({
                            tableId: doc.id,
                            chairs: chairs,
                        })
                    }
                })
                
                unityContent.send(
                    "GameManager",
                    "InsideBarUpdated",
                    JSON.stringify(insideBarData)
                );
            });
    }

    const acceptUser = (acceptUserJson) => {
        const acceptUserDto = JSON.parse(acceptUserJson);

        const acceptRejectFunction = functions.httpsCallable('acceptReject');
        let data = {
            uid: acceptUserDto.UserId,
            accept: true,
        };

        acceptRejectFunction(data)
            .then((result) => {

            })
            .catch(error => {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                console.log(error);
            });
    }

    const rejectUser = (rejectUserJson) => {
        const rejectUserDto = JSON.parse(rejectUserJson);

        const acceptRejectFunction = functions.httpsCallable('acceptReject');
        let data = {
            uid: rejectUserDto.UserId,
            accept: false,
        };

        acceptRejectFunction(data)
            .then(result => {

            })
            .catch(error => {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                console.log(error);
            });
    }

    const joinTable = (joinTableJson) => {
        const joinTableDto = JSON.parse(joinTableJson);
        
        const joinTableFunction = functions.httpsCallable('joinTable');
        let data = {
            pubId: barId,
            tableId: joinTableDto.TableId,
            chairId: joinTableDto.ChairId,
        };

        joinTableFunction(data)
            .then(result => {
                const unityResult = {
                    isSuccess: true,
                    message: "Success",
                }
                
                unityContent.send(
                    "GameManager",
                    "JoinTableFinished",
                    JSON.stringify(unityResult)
                );
            })
            .catch(error => {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                console.log(error);
                
                const unityResult = {
                    isSuccess: false,
                    message: error.message,
                }

                unityContent.send(
                    "GameManager",
                    "JoinTableFinished",
                    JSON.stringify(unityResult)
                );
            });
    }

    const leaveTable = (leaveTableJson) => {
        const leaveTableDto = JSON.parse(leaveTableJson);

        const leaveTableFunction = functions.httpsCallable('leaveTable');
        let data = {
            pubId: barId,
            tableId: leaveTableDto.TableId,
        };

        leaveTableFunction(data)
            .then(result => {

            })
            .catch(error => {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                console.log(error);
            });
    }

    const getLocalPubs = (lat, lng) => {
        if(isInsideBar || !mymap || !mymap.current)
            return;
        
        var pubsRef = firestore.collection("pubs")

        //get my geohash and neighbouring geohashes
        const geohash = Geohash.encode(lat, lng, 6);
        const neighbours = Geohash.neighbours(geohash)
        const localNearbyPubs = []

        pubsRef.where('geohash', 'in', [geohash, neighbours['n'], neighbours['ne'], neighbours['e'], neighbours['se'], neighbours['s'], neighbours['sw'], neighbours['w'], neighbours['nw']])
            .get()
            .then(querySnapshot=>{
                querySnapshot.forEach(doc => {
                    localNearbyPubs.push(doc.data())
                });
                setNearbyPubs(localNearbyPubs)

            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
    }

    const shouldDisplay = (coordinates) => {
        //Should the pub listing be displayed? We only want to list pubs currently on the screen
        return coordinates[1] >minLatitude && coordinates[1] < maxLatitude && coordinates[0] > minLongitude && coordinates[0] < maxLongitude
    }

    const moveUserToPub = (pubId) => {
        const pubRef = firestore.collection("pubs").doc(pubId);
        pubRef.get().then(function(doc) {
            if (doc.exists) {
                const pubToVisit = doc.data()
                const viewport = {
                    center: [pubToVisit.latitude, pubToVisit.longitude],
                    zoom: 16,
                }
                setViewport(viewport)
                getLocalPubs(pubToVisit.latitude, pubToVisit.longitude);
                // console.log("setting active",pubToVisit.osm )
                setActivePub(pubToVisit.osm)
            } else {
                // console.log("The pub does not exist, or at least no-one has been there.", pubId);
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    const prov = OpenStreetMapProvider();
    const GeoSearchControlElement = withLeaflet(SearchControl);

    const updateTabValue = (event, newValue) => {
        setTabValue(newValue)
    }

    const a11yProps = (index) => {
        return {
            id: `nav-tab-${index}`,
            'aria-controls': `nav-tabpanel-${index}`,
        };
    }

    const LinkTab = (props) => {
        return (
            <Tab
                component="a"
                onClick={(event) => {
                    event.preventDefault();
                }}
                {...props}
            />
        );
    }

    const hasFriendsInPub = (pubId) => {
        let friendsInPub = 0
        friends.forEach(friend => {
            if(friend.pubId === (pubId.id.toString().replace('/', '')) && wasOnlineSinceDuration(friend.lastActive, 30, 'minutes') ) {
                friendsInPub++;
            }
        });

        return friendsInPub
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && <div>{children}</div>}
            </div>
        );
    }

    const sendFeedback = (feedback) => {
        setFeedbackOpen(false)
        const feedbackRef = firestore.collection("feedback").doc(auth.currentUser.uid+Date.now());
        feedbackRef.set({
            firebaseUid: auth.currentUser.uid,
            displayName: auth.currentUser.displayName,
            date: Date.now(),
            feedback: feedback,
        },{merge: true})
    }

    const claimPub = (email) => {
        setFeedbackOpen(false)
        const feedbackRef = firestore.collection("claimPub").doc(auth.currentUser.uid+Date.now());
        feedbackRef.set({
            firebaseUid: auth.currentUser.uid,
            displayName: auth.currentUser.displayName,
            pubId: activePub.id.toString().replace('/',''),
            pubName:activePub.properties.name,
            date: Date.now(),
            email: email,
        },{merge: true})
    }

    const claimPubModalOpen = (email) => {
        setClaimFeedbackOpen(true)
    }
    
    const handleClose = () => {
        setOpen(false)
        setFeedbackOpen(false)
        setSnackBarOpen(false)
        setClaimFeedbackOpen(false)

        unityContent.send(
            "GameManager",
            "TurnOnKeyboardFocus",
        );
    }

    const createInvitation = (pub) => {
        setSnackBarOpen(true)
        setIsCreatingInvite(true);

        // create id from map node. 
        const id = pub.properties.id.toString().replace('/','')
        // create geohash
        const geohash = Geohash.encode(pub.geometry.coordinates[1], pub.geometry.coordinates[0], geohashSize);

        const createInvitationFunction = functions.httpsCallable('createInvitation');
        let createInvitationData = {};
        createInvitationData = {
            id: id,
            name: pub.properties.name,
            latitude: pub.geometry.coordinates[1],
            longitude: pub.geometry.coordinates[0],
            osm: pub,
            geohash: geohash,
            uDateTime: uDateTime,
        };

        createInvitationFunction(createInvitationData)
            .then(result => {
                setIsCreatingInvite(false);
            })
            .catch(error => {
                setIsCreatingInvite(false);
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                console.log(error);
            });
    }

    let sideMenuClass = classes.pubList;
    if(!menuIsOpen) {
        sideMenuClass = clsx(classes.pubList, classes.pubListClosed);
    }
    let sideMenuHandleClass = classes.pubListHandle;
    if(!menuIsOpen) {
        sideMenuHandleClass = clsx(classes.pubListHandle, classes.pubListHandleClosed);
    }

    const filteredPubs = geojson && geojson.sort((a, b) => (a.properties.name > b.properties.name) ? 1 : -1)
        .filter(pub => (pub.geometry.coordinates && shouldDisplay(pub.geometry.coordinates) && pub.properties && pub.properties.name && (!showOnlyOccupied || (pub.users && pub.users.length > 0))));

    return (
        <div className={classes.root}>
            {
                snackBarOpen &&
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    severity="success"
                    open={snackBarOpen}
                    onClose={handleClose}
                    message="Bar invitation copied. Paste the link to your friends to invite them!"
                    autoHideDuration={5000}
                />
            }
            <div className={classes.betaTag} onClick={event => setFeedbackOpen(true)}>
                <Typography style={{fontSize: '2em', letterSpacing: '0.1em', lineHeight: '1em'}}>
                    BETA
                </Typography>
                <Typography style={{fontSize: '1.2em'}}>
                    Tell us what you want in Pubmash
                </Typography>
            </div>
            <FeedbackDialog fullScreen={fullScreen} open={feedbackOpen} handleClose={handleClose}
                            sendFeedback={sendFeedback}/>
            <WhatIsPubMashDialog fullScreen={fullScreen} open={open} handleClose={handleClose}/>
            <ClaimPub pubName={(isInsideBar && isInsideBar.name) ? isInsideBar.name: ''} fullScreen={fullScreen} open={claimFeedbackOpen} handleClose={handleClose} claimPub={claimPub}/>
            {
                isInsideBar &&
                <div style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1100,
                    backgroundColor: 'white'
                }}>
                    <div style={{
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>

                        <div>
                            <IconButton onClick={event => leavePub()}>
                                <NavigateBeforeIcon/>
                            </IconButton>
                            <Button variant={"contained"} style={{margin: 'auto 20px'}} color={"primary"}
                                    onClick={() => {
                                        unityContent.send(
                                            "GameManager",
                                            "TurnOffKeyboardFocus",
                                        );
                                        setFeedbackOpen(true)
                                    }}>
                                Tell us what you want in Pubmash
                            </Button>
                        </div>
                        <Typography variant={"h6"}>
                            {unityLoading ? `Loading ${isInsideBar.name}...` : `You have arrived at ${isInsideBar.name}`}
                        </Typography>
                        <Button variant={"contained"} onClick={() => claimPubModalOpen()} style={{margin: 'auto 5px'}} color={"primary"}>
                            Donate to the real {isInsideBar.name}
                        </Button>
                    </div>
                    {
                        unityLoading &&
                        <div style={{
                            position: 'fixed',
                            top: 50,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1101,
                            backgroundColor: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress style={{width: '50px', height: '50px'}}/>
                        </div>
                    }
                    <div style={{height: 'calc(100% - 50px)'}}>
                        <Unity unityContent={unityContent}/>
                    </div>
                </div>
            }
            {
                !isInsideBar && 
                    <Sidebar 
                        user={user} 
                        friends={friends} 
                        pubs={filteredPubs}
                        popularPubs={popularPubs}
                        activePub={activePub}
                        setOpenWhatIs={setOpen}
                        selectPub={selectPub}
                        loginWithFacebook={loginWithFacebook} 
                        loginWithGoogle={loginWithGoogle} 
                        logout={logout} 
                    />
            }
            {
                !isInsideBar && 
                <div style={{height: '100%'}}>
                    <Map viewport={viewport}
                         ref={mymap}
                         onViewportChanged={(e) => {
                             onViewportChanged(e);
                         }}
                         enableHighAccuracy={true}
                         zoomControl={false}
                         style={{height: '100%'}}
                         className={classes.map}
                    >
                        <GeoSearchControlElement
                            zoomToResult={true}
                            provider={prov}
                            showMarker={false}
                            showPopup={false}
                            popupFormat={({query, result}) => result.label}
                            maxMarkers={3}
                            retainZoomLevel={false}
                            animateZoom={true}
                            autoClose={true}
                            searchLabel={'Search'}
                            keepResult={true}
                            style='bar'
                        />
                        <ZoomControl
                            position={'bottomright'}
                        />
                        <TileLayer
                            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MarkerClusterGroup spiderfyOnMaxZoom={false} disableClusteringAtZoom={17}>
                            {
                                geojson && geojson.map(pub => (
                                    pub.geometry.coordinates && pub.properties && pub.properties.name && (!showOnlyOccupied || (pub.users && pub.users.length > 0)) &&
                                    <Marker
                                        key={pub.id}
                                        position={[pub.geometry.coordinates[1], pub.geometry.coordinates[0]]}
                                        onClick={() => {
                                            setActivePub(pub);
                                        }}
                                        icon={pubIcon}
                                    />
                                ))
                            }
                        </MarkerClusterGroup>
                        {
                            activePub && (
                                <Popup
                                    className={classes.popup}
                                    position={[
                                        activePub.geometry.coordinates[1]-0.0006,
                                        activePub.geometry.coordinates[0]
                                    ]}
                                    onClose={() => {
                                        setActivePub(null);
                                    }}
                                >
                                    <Grid container spacing={1} justify={"center"} className={classes.popupContainer}>
                                        <Typography variant={"h6"} className={classes.pubName}>
                                            {activePub.properties.name}
                                        </Typography>
                                        {/*<Typography variant={"h6"} className={classes.pubEvent}>*/}
                                        {/*    Live show starts at 9PM*/}
                                        {/*</Typography>*/}
                                        {
                                            auth.currentUser && friends && hasFriendsInPub(activePub) > 0 &&
                                            <Typography variant={"body2"}>
                                                {hasFriendsInPub(activePub)} friends in the pub.
                                            </Typography>
                                        }
                                        {
                                            invitationDetails && invitationDetails.displayName && invitationDetails.pubId === activePub.id.toString().replace('/', '') &&
                                            <Typography variant={"body2"} className={classes.popupInviteText}>
                                                <Avatar className={classes.popupInviteAvatar}
                                                        src={invitationDetails.userPhotoURL}
                                                />
                                                {
                                                    invitationDetails.displayName} invited you to the
                                                pub {moment(invitationDetails.invitationDate).fromNow()}
                                            </Typography>
                                        }
                                        {
                                            auth.currentUser ?
                                                <React.Fragment>
                                                    <Grid container spacing={1} justify={"center"}>
                                                        <Grid item xs={6}>
                                                            <Button className={classes.buttonEnter}
                                                                    disabled={isEnteringPub}
                                                                    onClick={() => enterPub(activePub)}
                                                                    variant="contained" 
                                                            >
                                                                {
                                                                    isEnteringPub
                                                                        ? "Entering pub"
                                                                        : "Enter pub"
                                                                }
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <CopyToClipboard
                                                                text={"https://www.pubmash.com/?invid=" + uDateTime + auth.currentUser.uid}>
                                                                <Button onClick={() => createInvitation(activePub)}
                                                                        disabled={isCreatingInvite}
                                                                        className={classes.buttonEnter} 
                                                                        variant="contained"
                                                                >
                                                                    {
                                                                        isCreatingInvite
                                                                            ? "Creating link"
                                                                            : "Invite friends"
                                                                    }
                                                                </Button>
                                                            </CopyToClipboard>
                                                        </Grid>
                                                        {
                                                            !activePub.discoveredBy &&
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" component={"span"} className={classes.pubUndiscovered}>
                                                                    {"Pub is undiscovered!"}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        {
                                                            activePub.discoveredBy && friends.length > 0 &&
                                                            <Grid item xs={12}>
                                                                <div className={classes.avatar}>
                                                                    {
                                                                        friends.map((friend, index) => (
                                                                            friend.lastActive && wasOnlineSinceDuration(friend.lastActive, 30, 'minutes') && friend.pubId === activePub.id.toString().replace('/', '') &&// this seems to cause the pub is empty to appear even though it has nothing to do with that area of code
                                                                            <Avatar className={classes.popupAvatar} key={index}
                                                                                    src={'https://graph.facebook.com/' + friend.facebookUid + '/picture'}
                                                                            />
                                                                        ))
                                                                    }
                                                                </div>
                                                            </Grid>
                                                        }
                                                        {
                                                            activePub.discoveredBy &&
                                                            <Grid item xs={12} className={classes.popupBottomRow}>
                                                                <Typography component={"span"}>
                                                                    {/*<IconButton className={classes.popupBottomRowFavouriteButton}>*/}
                                                                    {/*    <FavoriteBorderIcon />*/}
                                                                    {/*</IconButton>*/}
                                                                    {/*50 favourites*/}
                                                                </Typography>
                                                                <Typography component={"span"} className={classes.popupBottomRowPubCount}>
                                                                    <GroupIcon style={{marginRight: '10px'}} />
                                                                    {activePub.users ? activePub.users.length : 0} / {activePub.userLimit ? activePub.userLimit : 0}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </React.Fragment>
                                                :
                                                <div>
                                                    <Button className={classes.buttonEnter} onClick={() => loginWithFacebook(true)}
                                                            variant="contained" color="primary">
                                                        Sign in with Facebook
                                                    </Button>
                                                    <Button className={classes.buttonEnter} onClick={() => loginWithGoogle(true)}
                                                            variant="contained" color="primary">
                                                        Sign in with Google
                                                    </Button>
                                                </div>
                                        }
                                    </Grid>
                                </Popup>
                            )
                        }
                    </Map>
                </div>
            }
        </div>
    );
}

export default App;