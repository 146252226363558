import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles/";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {ReactComponent as PubSign} from './assets/logoyellow.svg';
import {firestore, analytics, auth} from './helpers/firebase';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';


const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        //margin: theme.spacing(1),
    },

    first: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
    },
    second: {
        marginTop: '5px'
    },
    third: {

        paddingTop: '30px'
    },
    fourth: {
        paddingTop: '30px'
    },
    fonts: {
        fontFamily: 'Verdana',
        textAlign: 'center',
    },
    five: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
    },
    pubCardBackground: {
        display: 'flex',
        height: '100%',
        width: '90%',
        borderRadius: '100%',
        

    },
    welcome: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',

    },

    notAvailable: {
        fontWeight: 'bold',
        fontFamily: 'Verdana',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
    },

    button: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        fontFamily: 'Verdana',
    },
    textfield: {
        fontFamily: 'Verdana',
    }
}));

const Mobile = (props) => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [open, setOpen] = useState(false);

    const signUp = () => {
        const emailAddress = email
        const feedbackRef = firestore().collection("appSignups").doc(auth().currentUser.uid+Date.now());
        feedbackRef.set({
            email: emailAddress,
        },{merge: true})
        setOpen(true)
        setEmail("")
    }

    return (
        
        <div style={{ padding: 20 }}>
        <Collapse in={open}>
              <Alert variant="filled" 
                    severity="success"
                    onClick={() => {
                        setOpen(false);
                    }} 
                    onClose={() => {}}>Thanks! We'll keep you posted.</Alert>
              </Collapse>
 

            <Grid container spacing={0} className={classes.container}>
                <Grid item xs={2} className={classes.first}>
                    <PubSign className={classes.pubCardBackground}/>
                </Grid>
                <Grid item xs={10} className={classes.second}>
                    <Typography className={classes.welcome} variant={"h4"}> Welcome to Pubmash!</Typography>
                </Grid>
                <Grid item xs={12} className={classes.third}>
                    <Typography className={classes.fonts} >
                        Digital pub nights are now a thing!
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography className={classes.fonts} >
                        Pubmash is the perfect place for your next pub night from the comfort of your own home.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.third}>
                    <Typography className={classes.fonts} >
                        Choose a pub, and sit around a table with your friends and chat like in a real pub!
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.third}>
                    <Typography className={classes.fonts} >
                         You can just hang out together, watch videos, or you can even meet others also in the pub!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.fonts}> </Typography>
                </Grid>
           

                <Grid item xs={12} className={classes.fourth} >
                    <Typography className={classes.notAvailable}>The Pubmash app for iOS and Android is on its way! Until then visit pubmash.com on your Laptop or Mac to join the pub!</Typography>
                </Grid>

                <Grid item xs={12} className={classes.fourth} >
                    <TextField
                        style={{ margin: 8 }}
                        placeholder="Enter email"
                        fullWidth
                        multiline
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => setEmail(event.target.value)}
                        className={classes.textfield}
                        value={email}
                    />
                </Grid>
                <Grid item xs={12} className={classes.five} >
                    <Button variant={"contained"} className={classes.button} color={"primary"}
                            onClick={() => signUp()}>
                        Notify me when the app is ready
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default Mobile;