import firebase from 'firebase'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCTZ_2bRC-K0mfxqsCXln8ZtRgHlJF7u_4",
    authDomain: "pub3-73eff.firebaseapp.com",
    databaseURL: "https://pub3-73eff.firebaseio.com",
    // databaseURL: "http://localhost:9000/?ns=pub3-73eff",
    projectId: "pub3-73eff",
    storageBucket: "pub3-73eff.appspot.com",
    messagingSenderId: "280338830828",
    appId: "1:280338830828:web:65b561aacb0b13a1279cc9",
    measurementId: "G-64J7VXDFJL"
};

// Your web app's Firebase configuration
const firebaseConfigStaging = {
    apiKey: "AIzaSyAFougSuKpk0kCNjn--_VR1YrRf6GuvEfQ",
    authDomain: "virtual-bar-staging.firebaseapp.com",
    databaseURL: "https://virtual-bar-staging.firebaseio.com",
    projectId: "virtual-bar-staging",
    storageBucket: "virtual-bar-staging.appspot.com",
    messagingSenderId: "695530315674",
    appId: "1:695530315674:web:e8dca59f142d4fee74e423",
    measurementId: "G-ZH43BNTPH7"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const fbAuthProvider = new firebase.auth.FacebookAuthProvider();
fbAuthProvider.addScope('user_friends');

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

firebase.analytics();
const auth = firebaseApp.auth();
const firestore = firebaseApp.firestore();
const database = firebaseApp.database();
const analytics = firebaseApp.analytics();
const functions = firebaseApp.functions();

// if (window.location.hostname === "localhost") {
//   console.log("localhost detected!");
//   db.settings({
//     host: "localhost:8080",
//     ssl: false
//   });
// }

export { firestore, auth, database, analytics, functions, fbAuthProvider, googleAuthProvider};