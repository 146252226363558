import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Mobile from './Mobile';
import Report from './Report';
import * as serviceWorker from './serviceWorker';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {CssBaseline} from "@material-ui/core";
import {isMobile} from 'react-device-detect';

const getCurrentTheme = () => {
    let primaryColor = '#16A085';
    let primaryTextColor = "#FFFFFF";
    let secondaryColor = "#E74C3C";
    let secondaryTextColor = "#E74C3C";

    return createMuiTheme({
        palette: {
            primary: {
                main: primaryColor,
            },
            secondary: {
                main: secondaryColor,
            }
        },
        typography: {
            useNextVariants: true,
            fontFamily: [
                'Bebas Neue',
                "Lato",
                'Roboto',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        overrides: {
            MuiButton: {
                contained: {
                    boxShadow: '0px 0px 6.7803px rgba(22, 160, 133, 0)',
                    borderRadius: '6.5px',
                    padding: '10px 20px',
                    backgroundColor: '#16A085',
                    color: '#FFF',
                    '&:hover': {
                        backgroundColor: '#16A085',
                        boxShadow: '0px 0px 6.7803px rgba(22, 160, 133, 0.5)',
                    },
                },
                outlined: {
                    border: '1px dashed #FFFFFF',
                    boxSizing: 'border-box',
                    borderRadius: '10px',
                    color: 'rgba(255,255,255,0.5)',
                    '&:hover': {
                        background: 'rgba(255,255,255,1)',
                        color: '#330F1B',
                    }
                },
                fullWidth: {
                    width: '-webkit-fill-available',
                },
            },
            MuiCard: {
                root: {
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.12)',
                    borderRadius: '10px',
                },
            },
            MuiCardHeader: {
                root: {
                    background: 'inherit',
                    alignItems: 'normal',
                    maxHeight: '52px',
                },
                title: {
                    fontWeight: 'bold',
                    fontSize: '24px',
                    lineHeight: '22px',
                    color: '#35323C',
                    height: '30px',
                    margin: '10px 0px 5px 0px'
                },
                subheader: {
                    lineHeight: '22px',
                    fontSize: '18px',
                    color: '#8E8E93',
                },
            },
            MuiCardContent: {
                root: {
                    background: 'inherit',
                    borderRadius: '5px',
                },
            },
            MuiCardActions: {
                root: {
                    borderTop: 'solid 1px #eee',
                    background: 'white',
                    //background: '#FFFBF2',
                    justifyContent: 'space-between',
                },
            },
            MuiPaper: {
                elevation2: {
                    boxShadow: 'none',
                },
            },
            MuiMobileStepper: {
                root: {
                    background: '#dbdde2'
                }
            },
            MuiPopover: {
                root: {
                },
                paper: {
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                    borderRadius: '10px 10px 10px 10px',
                }
            },
        },
    });
};


ReactDOM.render(
    <div>
        <MuiThemeProvider theme={getCurrentTheme()}>
            <CssBaseline />
            <Router>
                <Routes>
                    {isMobile ? <Route path="/" component={Mobile} />: <Route path="/" exact element={<App />} /> }
                    {isMobile ? <Route path="(/:pubid)(/:t)" component={Mobile} />: <Route path="(/:pubid)(/:t)" exact element={<App />} /> }
                    <Route path="/report" component={<Report />} />
                </Routes>
            </Router>
        </MuiThemeProvider>
    </div>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
